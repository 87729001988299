import React from 'react';
import Layout from '../components/layout/Layout';
import "../style/styles.css"
import { BackButton } from '../components/ButtonDesign';

const Mpurse = () => (
  <Layout>
    <div className="px-4 md:px-32">
      <div className="w-full h-full py-12 md:py-24 mx-auto flex justify-center items-center">
        <div className='w-full text-xl md:text-3xl font-bold'>
          <p className="text-lg text-left border-b-2">Mpurse？</p>
          <div className="py-4 sm:p-8 text-sm md:text-xl text-left break-all">
            <div>
              <p className="mb-4">Mpurse とは Monaparty, Monacoin に対応したウォレットです。Google Chrome, Firefox, iOS, Android に対応しており、インストールすることにより使用できます。</p>
              <p>Mpurse is a wallet compatible with Monaparty and Monacoin. It is compatible with Google Chrome, Firefox, iOS, and Android, and can be used by installing it.</p>
            </div>
            <div className="border-2 my-6 p-2 bg-pink-400 hover:opacity-75">
              <a className="" href="https://github.com/tadajam/mpurse">Mpurse (GitHub リンク) &#x1f448;</a>
            </div>
            <div className="">
              <p　className="">使い方は下記の記事が参考になりますのでご覧下さい。（外部リンク）</p>
            </div>
            <div className="border-2 my-6 p-2 bg-pink-400 hover:opacity-75">
              <a className="" href="https://spotlight.soy/detail?article_id=lb0f3esfe">【2022年版】Mpurseのウォレット（口座）開設方法 by にすた氏 &#x1f448;</a>
            </div>
            <BackButton />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Mpurse;